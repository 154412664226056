<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-select
            v-model="searchForm.year"
            placeholder="年份"
            size="small"
            filterable
            style="width:140px;margin-right:15px;">
          <el-option v-for="item in yearOptions" :label="item+'年'" :value="Number(item)" :key="item"></el-option>
        </el-select>
        <el-input v-model="searchForm.departmentName" placeholder="请输入项目名称" size="small" style="width:140px;" clearable></el-input>
      </div>
      <div class="search">
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiBan">导出</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="searchTaiBan">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table :data="TaiBantableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center"  prop="name" label="月份" width="200">
          <el-table-column align="center" prop="name" label="项目名称" width="200">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top">
                <div class="diaolanName" :title="scope.row.name">{{scope.row.name}}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-for="(item, index) in tableHeaderArrays" :key="index" :label="item" :prop="tableHeaderArrays[index]">
          <template slot-scope="scope">
            <div>{{scope.row.countList[index] || '' }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import apis from '../api/security-check.js'
import {downloadFile,getDate} from '@/utils/utils'
import BaseURL from '@/apis/baseurl'
export default {
  name: "two",

  components: {},

  props: {},

  data() {
    return {
      TaiBantableData:[],
      tableHeaderArrays:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月','年累计','开工累计',],
      yearOptions:[],
      monthOptions:['1','2','3','4','5','6','7','8','9','10','11','12'],
      tableHeaderArray: [],
      searchForm:{
        departmentName: '',
        year:'',
        machineType: 0
      }
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      this.initTaiBan()
    }
  },

  mounted() {
    this.yearOptions = this.getYear()
    this.searchForm.year =getDate(new Date()).year
    this.initTaiBan()
  },

  methods: {
    initTaiBan() {
      const params = {
        departmentCode: this.optionItem.departmentCode,
        year: this.searchForm.year,
        departmentName: this.searchForm.departmentName,
        machineType: 0
      }
      apis.largeMachineRecordTotalByYear(params).then(res => {
        this.TaiBantableData = res
      })
    },
    getYear(){
      const array = []
      for (let i=0;i<60;i++){
        if (i<10){
          array.push('200'+i)
        }else{
          array.push('20'+i)
        }
      }
      return array
    },
    searchTaiBan(){
      this.initTaiBan()
    },
    exportTaiBan(){
      const params = {
        departmentCode: this.optionItem.departmentCode,
        year: this.searchForm.year,
        departmentName: this.searchForm.departmentName
      }
      // const url = BaseURL +`/pc/hangingBasket/exportByYear?departmentCode=${params.departmentCode}&year=${params.year}&vendor=${params.vendor}`
      const url = BaseURL + `/admin/public/exportTotalMachineByYear?machineType=0&year=${params.year}&departmentName=${params.departmentName}`
      downloadFile(url, {}, '吊车台班汇总台账.xls')
    },
  }
}
</script>

<style scoped>
/*::v-deep 这里主要的作用就是用来强制修改element默认的样式*/
::v-deep .el-table thead.is-group th {
  background: none;
  padding: 0px;
}

::v-deep .el-table thead.is-group tr:first-of-type th:nth-of-type(2) {
  border-bottom: none; /*中间的横线去掉*/
}

::v-deep .el-table thead.is-group tr:first-of-type th:nth-of-type(2) div.cell {
  text-align: right; /*上边文字靠右*/
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type div.cell {
  text-align: left; /*下边文字靠左*/
}
/*核心代码*/
::v-deep .el-table thead.is-group tr:first-of-type th:nth-of-type(2):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 150px; /*斜线的长度*/
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-43deg); /*调整斜线的角度*/
  transform: rotate(-68deg); /*调整斜线的角度*/
  -webkit-transform-origin: top;
  transform-origin: top;
}

::v-deep .el-table thead.is-group tr:last-of-type th:nth-of-type(1):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 150px; /*斜线的长度*/
  bottom: 0;
  right: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-45deg); /*调整斜线的角度*/
  transform: rotate(-69deg); /*调整斜线的角度*/
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
/*::v-deep .el-table thead.is-group th {*/
/*  height: 27.4px;*/
/*}*/

.diaolanName {
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
